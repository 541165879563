<template>
    <div class="wrapper">
        <div class="sidebar">
            <div class="sidebar-item" :class="{ active: index === currentIndex }"
                 @click="clickMenuItem(index)"
                 v-for="(item, index) in getGoodList" :key="index">
                <img v-show="item.iconUrl" :src="item.iconUrl" alt="">
                <span>{{ item.title }}</span>
            </div>
        </div>
        <!--  -->
        <div class="foods-wrapper">
            <ul ref="foodsUl">
                <li
                        class="food-list-hook"
                        v-for="(good, index) in getGoodList"
                        :key="index"
                >
                    <span class="title">{{ good.title }}</span>
                    <ul>
                        <li
                                class="food-item"
                                v-for="(food, index) in good.sysProducts"
                                :key="index"
                        >
                            <div class="icon">
                                <img width="57" height="57" :src="food.foodUrl"/>
                            </div>
                            <div class="content">
                                <input v-model="food.productName" style="display: none">
                                <input v-model="food.productPrice" style="display: none">
                                <span class="name">{{ food.productName }}</span>
                                <!-- <p class="desc">{{ food.description }}</p> -->
                                <!-- <div class="extra">
                                  <span class="count">月售{{ food.sellCount }}份</span>
                                  <span>好评率{{ food.rating }}%</span>
                                </div> -->
                                <div class="price">
                                <span class="priceAndNum">
                                    <span class="now">¥{{ food.productPrice }}</span>
                                    <!-- <span>{{ food.price }}</span> -->
                                    <span >
                                        <!-- <el-input-number style="width: 120px;font-size: 12px" :value="num[food.productId]"
                                                         v-model="number[food.productId]" :min="0" :max="10"
                                                         @change="updateNum(food.productId,food.title,food.price)"></el-input-number> -->
                                        <van-stepper v-model="food.productNum" theme="round" button-size="22" :min="0" :max="10"
                                                     disable-input @change="updateNum"/>
                                    </span>
                                </span>
                                    <!--<div style="text-align: right">
                                        <img class="image_2" @click="addGoods(food.price)"
                                             referrerpolicy="no-referrer" src="./assets/img/jianhao.png"/>
                                        <img class="image_2" @click="subGoods(food.price)"
                                             referrerpolicy="no-referrer" src="./assets/img/jiahao.png"/>
                                    </div>-->
                                </div>
                                <!-- <div class="group_15 flex-row">
                                    <div class="text-wrapper_12">
                                        <span class="text_30">￥</span>
                                        <span class="text_31">{{orderParams.orderPrice}}</span>
                                    </div>
                                    <span class="text_32">已选{{orderParams.orderNum}}件</span>
                                    <div class="text-wrapper_13 flex-col">
                                        <span class="text_33" @click="addOrderInfo">提交订单</span>
                                    </div>
                                </div> -->
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
<!--

        <div v-if="show" class="box_10s flex-col">
            <div class="group_17s flex-col">
                <span class="text_29s">#01号桌&nbsp;&nbsp;&nbsp;请输入就餐人数</span>
                &lt;!&ndash;   <div class="box_11s flex-row justify-between">
                     <div class="text-wrapper_12s flex-col"><span class="text_30s">1</span></div>
                     <div class="text-wrapper_13s flex-col"><span class="text_31s">2</span></div>
                     <div class="text-wrapper_14s flex-col"><span class="text_32s">3</span></div>
                     <div class="text-wrapper_15s flex-col"><span class="text_33s">4</span></div>
                   </div>
                   <div class="box_12s flex-row justify-between">
                     <div class="text-wrapper_16s flex-col"><span class="text_34s">5</span></div>
                     <div class="text-wrapper_17s flex-col"><span class="text_35s">6</span></div>
                     <div class="text-wrapper_18s flex-col"><span class="text_36s">7</span></div>
                     <div class="text-wrapper_19s flex-col"><span class="text_37s">8</span></div>
                   </div>
                   <div class="box_13s flex-row justify-between">
                     <div class="text-wrapper_20s flex-col"><span class="text_38s">9</span></div>
                     <div class="text-wrapper_21s flex-col"><span class="text_39s">10</span></div>
                     <div class="text-wrapper_22s flex-col"><span class="text_40s">11</span></div>
                     <div class="text-wrapper_23s flex-col"><span class="text_41s">12</span></div>
                   </div>&ndash;&gt;
                <div class="text-wrapper_24s flex-col">
                    <span class="text_42s">
                        请输入数字
                    </span>
                    &lt;!&ndash;<input type="text" class="text_42s" placeholder="请输入数字"/>&ndash;&gt;
                </div>
                <div class="text-wrapper_25s flex-col">
                    <span class="text_43s" @click="userOrderInfo">确认</span>
                </div>
            </div>
        </div>
-->

    </div>
</template>

<script>
    import {queryProductTypeGoods,addOrderInfo,queryOrderByShopIdAndCode} from "@/api/order";
    import axios from 'axios';
    import BScroll from "better-scroll";

    export default {
        name: '',
        components: {},
        data() {
            return {
                number:[],
                show: false,
                baseURL: axios.defaults.baseURL,
                productTypeParam: {
                    dr: 1,
                    type: 1,
                    shopId: this.$route.query.shopId
                },
                orderData:{
                    shopId: this.$route.query.shopId,
                    tableCode: this.$route.query.tableNumber
                },
                scrollY: 0, // 右侧滑动的Y轴坐标 (滑动过程时实时变化)
                tops: [], // 所有右侧分类li的top组成的数组  (列表第一次显示后就不再变化)
                getGoodList: [],
                sysProductList:[],
                orderParams:{
                    shopId: this.$route.query.shopId,
                    tableId: this.$route.query.tableId,
                    tableCode: this.$route.query.tableNumber,
                    orderPrice:0,
                    orderNum:0,
                    addressId:0,
                    type:1,//1菜品2商品3购买会员订单
                    sysOrderDetails:[]
                },
                sysOrderDetail:{}
            };
        },
        computed: {
            // 计算得到当前分类的下标
            currentIndex() {
                // 初始化和相关数据发生了变化时执行
                // 得到条件数据
                const {scrollY, tops} = this;
                // 根据条件计算产生一个结果
                const index = tops.findIndex((top, index) => {
                    // scrollY>=当前top && scrollY<下一个top
                    return scrollY >= top && scrollY < tops[index + 1];
                });
                // 返回结果
                return index;
            }
        },
        mounted() {
            if(!this.$route.query.orderCode){
                //根据当前shopId和餐桌号查询当前餐桌得订单号
                queryOrderByShopIdAndCode(this.orderData).then(response => {
                    if(response.code==200){
                        this.$router.push({ name: 'orderInfo', query: { orderCode: response.data.orderCode}});
                        return
                    }
                });
            }

            this.orderParams.sysOrderDetails = []
            queryProductTypeGoods(this.productTypeParam).then(response => {
                if (response.code == 200) {
                    response.data.forEach((item, index) => {
                        if (index < 1) {
                            item.iconUrl = require('./assets/img/hot.png');
                            item.key = 'hot';
                        } else {
                            item.key = '';
                        }
                        item.id = item.productTypeId;
                        item.title = item.productTypeName;
                        item.sysProducts.forEach((item) => {
                            item.foodUrl = this.baseURL + item.smallImagePath;
                            item.productNum = 0;
                            this.sysProductList.push(item)
                        })
                        this.getGoodList.push(item)
                    })

                    this.$nextTick(() => {
                        this.initView();
                        this.initTops();
                    });
                }
            });
        },
        methods: {
            updateNum() {
                //console.log("sysProductList:", this.sysProductList);
                let total = 0;
                let totalNum = 0;
                let sysOrderDetails = []
                this.sysProductList.forEach((item) => {
                    if(item.productNum>0){
                        total += Number(item.productPrice) * Number(item.productNum);
                        totalNum += Number(item.productNum);
                        sysOrderDetails.push(item)
                    }
                })
                console.log("sysOrderDetails:", sysOrderDetails);
                this.orderParams.sysOrderDetails = sysOrderDetails
                this.orderParams.orderNum = totalNum
                this.orderParams.orderPrice = total
                //console.log("orderPrice:", this.orderParams.orderPrice);
                this.$emit('valuesent', this.orderParams);
            },
            addOrderInfo() {
                if(this.orderParams.orderPrice>0){
                    addOrderInfo(this.orderParams).then(response => {
                        if (response.code == 200) {
                            this.$router.push({ name: 'orderInfo', params: { orderCode: response.data.orderCode }});
                        }
                    });
                }else{
                    this.$model({
                        show: true,
                        title: "提示",
                        content: "请选择菜品",
                        confirmButton: false,
                        cancelButton: true
                    });
                    return false
                }

                this.show = true
            },
            userOrderInfo() {
                this.show = false
                this.$router.push('/orderInfo')
            },
            initView() {
                // 列表显示之后创建
                new BScroll(".sidebar", {
                    click: true
                });
                this.foodsScroll = new BScroll(".foods-wrapper", {
                    probeType: 2, // 因为惯性滑动不会触发
                    click: true
                });

                // 给右侧列表绑定scroll监听
                this.foodsScroll.on("scroll", ({x, y}) => {
                    console.log("scroll", x, y);
                    this.scrollY = Math.abs(y);
                });
                // 给右侧列表绑定scroll结束的监听
                this.foodsScroll.on("scrollEnd", ({x, y}) => {
                    console.log("scrollEnd", x, y);
                    this.scrollY = Math.abs(y);
                });
            },
            // 初始化tops
            initTops() {
                // 1. 初始化tops
                const tops = [];
                let top = 0;
                tops.push(top);
                // 2. 收集
                // 找到所有分类的li
                const lis = this.$refs.foodsUl.getElementsByClassName("food-list-hook");
                Array.prototype.slice.call(lis).forEach(li => {
                    top += li.clientHeight;
                    tops.push(top);
                });

                // 3. 更新数据
                this.tops = tops;
                console.log(tops);
            },
            clickMenuItem(index) {
                console.log(index);
                // 使用右侧列表滑动到对应的位置

                // 得到目标位置的scrollY
                const scrollY = this.tops[index];
                // 立即更新scrollY(让点击的分类项成为当前分类)
                this.scrollY = scrollY;
                // 平滑滑动右侧列表
                this.foodsScroll.scrollTo(0, -scrollY, 300);
            },

        }
    };
</script>
<style scoped>
    .wrapper {
        display: flex;
        height: 100%;
        overflow: auto;
    }

    .sidebar {
        width: 8.85rem;
        background-color: #ededed;
    }

    .image_2 {
        width: 1.547rem;
        height: 1.547rem;
        /* margin: 0 0 0 5.827rem;*/
        text-align: right;
    }

    .sidebar-item {
        padding: 1.46rem 1.46rem 1.46rem 0;
        /* padding-right: 1.46rem; */
        font-size: 1rem;
        text-align: right;
        background-color: #ededed;
    }

    .sidebar-item.active {
        background-color: #fff;
    }

    .sidebar-item img {
        width: 1.06rem;
        height: 1.23rem;
        margin-right: 3px;
    }

    .menuName {
        color: #333;
        font-size: 1.06rem;
        height: 1.06rem;
        margin: 0;
    }

    .foods-wrapper {
        flex: 1;
        padding: 0 1.33rem;
        /* padding: 0 1.33rem 1rem; */
        overflow: hidden;
    }

    .foods-wrapper .title {
        height: 1.06rem;
        line-height: 1.06rem;
        font-size: 1.06rem;
        color: #333;
        text-align: left;
        margin-top: 1.06rem
    }

    .food-item {
        display: flex;
        padding: 1rem 0;
        border-bottom: 1px solid #ddd;
    }

    .icon {
        flex: 0 0 57px;
        margin-right: 10px;
    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
    }

    .content .name {
        margin: 2px 0 8px 0;
        height: 14px;
        line-height: 14px;
        /*font-size: 14px;*/
        color: #333;
    }

    .desc, .extra {
        line-height: 10px;
        font-size: 10px;
        color: rgb(147, 153, 159);
    }

    .desc {
        line-height: 12px;
        margin-bottom: 8px;
    }

    .extra .count {
        margin-right: 12px;
    }

    .price {
        font-weight: 700;
        /* line-height: 24px;*/
    }
    .priceAndNum{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .now {
        margin-right: 8px;
        font-size: 14px;
        color: #d81e06;
    }
    .now label{
        color: #333;
        font-size: 16px;
    }
    .now span:nth-child(1) {
        font-size: 0.8rem;
    }

    .now span:nth-child(1) {
        font-size: 1.06rem;
    }

    .old {
        text-decoration: line-through;
        font-size: 10px;
        color: rgb(147, 153, 159);
    }

    .group_15 {
        background-color: rgba(255, 255, 255, 1);
        position: absolute;
        left: 0;
        top: 67.96rem;
        width: 33.12rem;
        height: 3.92rem;
        justify-content: flex-center;
    }

    .text-wrapper_12 {
        width: 5.6rem;
        height: 1.28rem;
        overflow-wrap: break-word;
        font-size: 0;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 1.28rem;
        margin: 1.334rem 0 0 1.307rem;
    }

    .text_30 {
        width: 5.6rem;
        height: 1.28rem;
        overflow-wrap: break-word;
        color: rgba(216, 30, 6, 1);
        font-size: 1.28rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 1.28rem;
    }

    .text_31 {
        width: 5.6rem;
        height: 1.28rem;
        overflow-wrap: break-word;
        color: rgba(216, 30, 6, 1);
        font-size: 1.653rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 1.654rem;
    }

    .text_32 {
        width: 3.734rem;
        height: 0.987rem;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 1.066rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 1.067rem;
        margin: 0 0 0 1.014rem;
    }

    .text-wrapper_13 {
        background-color: rgba(251, 116, 58, 1);
        border-radius: 49px;
        height: 2.614rem;
        width: 8.267rem;
        margin: 0.694rem 1.307rem 0 11.894rem;
    }

    .text_33 {
        width: 5.387rem;
        height: 1.12rem;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 1.173rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.174rem;
        margin: 0.747rem 0 0 1.414rem;
    }


    .box_10s {
        background-color: rgba(0, 0, 0, 0.7);
        height: 58.88rem;
        width: 33.12rem;
        position: absolute;
        left: 0;
        top: 151px;
    }

    .group_17s {
        width: 33.12rem;
        height: 47.894rem;
        background: url(./assets/img/32c0dcb40dfbaf04497e2a9342045b5a.png) -0.08rem 0rem no-repeat;
        background-size: 33.2rem 47.894rem;
        margin-top: 10.987rem;
    }

    .text_29s {
        width: 17.494rem;
        height: 1.387rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.44rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 1.44rem;
        margin: 3.92rem 0 0 2.614rem;
    }

    .box_11s {
        width: 27.04rem;
        height: 5.174rem;
        margin: 3.574rem 0 0 3.04rem;
    }

    .text-wrapper_12s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        width: 5.174rem;
    }

    .text_30s {
        width: 0.427rem;
        height: 1.147rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 2rem 0 0 2.374rem;
    }

    .text-wrapper_13s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        margin-left: 2.107rem;
        width: 5.174rem;
    }

    .text_31s {
        width: 0.8rem;
        height: 1.174rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 2rem 0 0 2.187rem;
    }

    .text-wrapper_14s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        margin-left: 2.134rem;
        width: 5.174rem;
    }

    .text_32s {
        width: 0.8rem;
        height: 1.2rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 1.974rem 0 0 2.187rem;
    }

    .text-wrapper_15s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        margin-left: 2.107rem;
        width: 5.174rem;
    }

    .text_33s {
        width: 0.907rem;
        height: 1.147rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 2rem 0 0 2.134rem;
    }

    .box_12s {
        width: 27.04rem;
        height: 5.174rem;
        margin: 2.427rem 0 0 3.04rem;
    }

    .text-wrapper_16s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        width: 5.174rem;
    }

    .text_34s {
        width: 0.8rem;
        height: 1.174rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 2rem 0 0 2.187rem;
    }

    .text-wrapper_17s {
        background-color: rgba(251, 116, 58, 1);
        height: 5.174rem;
        margin-left: 2.107rem;
        width: 5.174rem;
    }

    .text_35s {
        width: 0.854rem;
        height: 1.227rem;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 1.974rem 0 0 2.16rem;
    }

    .text-wrapper_18s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        margin-left: 2.134rem;
        width: 5.174rem;
    }

    .text_36s {
        width: 0.747rem;
        height: 1.147rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 2rem 0 0 2.214rem;
    }

    .text-wrapper_19s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        margin-left: 2.107rem;
        width: 5.174rem;
    }

    .text_37s {
        width: 0.854rem;
        height: 1.2rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 1.974rem 0 0 2.16rem;
    }

    .box_13s {
        width: 27.04rem;
        height: 5.174rem;
        margin: 2.427rem 0 0 3.04rem;
    }

    .text-wrapper_20s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        width: 5.174rem;
    }

    .text_38s {
        width: 0.8rem;
        height: 1.2rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 1.974rem 0 0 2.187rem;
    }

    .text-wrapper_21s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        margin-left: 2.107rem;
        width: 5.174rem;
    }

    .text_39s {
        width: 1.68rem;
        height: 1.2rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 1.974rem 0 0 1.734rem;
    }

    .text-wrapper_22s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        margin-left: 2.134rem;
        width: 5.174rem;
    }

    .text_40s {
        width: 1.387rem;
        height: 1.147rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 2rem 0 0 1.894rem;
    }

    .text-wrapper_23s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        margin-left: 2.107rem;
        width: 5.174rem;
    }

    .text_41s {
        width: 1.68rem;
        height: 1.174rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 2rem 0 0 1.76rem;
    }

    .text-wrapper_24s {
        background-color: rgba(255, 255, 255, 1);
        height: 4.534rem;
        border: 2px solid rgba(222, 222, 222, 1);
        width: 27.04rem;
        margin: 2.4rem 0 0 3.04rem;
    }

    .text_42s {
        width: 10.587rem;
        height: 1.254rem;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 1.333rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.334rem;
        margin: 1.627rem 0 0 1.654rem;
    }

    .text-wrapper_25s {
        background-color: rgba(251, 116, 58, 1);
        border-radius: 74px;
        height: 3.947rem;
        width: 27.2rem;
        margin: 3.92rem 0 3.84rem 2.88rem;
    }

    .text_43s {
        width: 2.667rem;
        height: 1.094rem;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 1.173rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.174rem;
        margin: 1.44rem 0 0 12.294rem;
    }
</style>
